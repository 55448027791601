<template>
  <div class="ppjsPage">
    <img src="../assets/ppjs/banner.png" class="banner" />
    <div class="bannerSj"></div>
    <!-- 品牌介绍 -->
    <div class="orangeBlueBlock">
      <div class="orangeBlueBlock1">品牌介绍</div>
      <div class="orangeBlueBlock2"></div>
    </div>
    <div class="ppjsFontsArea">
      <div class="blueTitle1">
        <div class="blueBlock1"></div>
        <div class="blueBlock2">品牌介绍</div>
      </div>
      <div class="ppjsFonts ppjsFonts1">
        星航小院仔航空俱乐部是针对3-15岁孩子，课程由哈尔滨工业大学及北京航空航天大学团队共同研发，有完善的课程体系，涉及到航模及无人机搭建、航模及无人机编程；课程、教具独立研发，轻双师教学模式解决老师招聘难、教学难问题，课程保质保量，只为当地孩子带来更好的科创教育！
      </div>
      <div class="ppjsFonts ppjsFonts1">
        多学科课程体系，让学生可以了解和学习飞行原理、空气动力学、材料与结构、航空发动机、无线电摇控、气象学，数学，物理等多学科的初步知识；课程目标即满足了家长对编程的高度认知，又有航模、无人机高端产品进行加持，做出市面上独特的产品、课程品类，为中国航空航天产业奠基人才贡献力量！
      </div>
      <div class="blueTitle1">
        <div class="blueBlock1"></div>
        <div class="blueBlock2">品牌特色</div>
      </div>
      <div class="ppjsFonts">
        星航小院仔航空俱乐结合了集团军工科研成果，打造了适用于青少年多元化课程体系与独家教具产品；
      </div>
      <div class="ppjsFonts">
        以航模、无人机为载体，融合独家的研发编程环境，让航模、无人机、编程完美结合，带给孩子不一样的创新体验!
      </div>
    </div>
    <!-- 发展历程 -->
    <div class="orangeBlueBlock">
      <div class="orangeBlueBlock1">发展历程</div>
      <div class="orangeBlueBlock2"></div>
    </div>
    <div class="fzlcParent">
      <div class="malu"></div>
      <div class="ty1">
        <div class="ty1Img"></div>
        <div class="fj1">
          <div class="year1">
            <div class="fonts1">集团公司成立</div>
          </div>
        </div>
      </div>
      <div class="ty2">
        <div class="ty2Img"></div>
        <div class="fj2">
          <div class="year2">
            <div class="fonts2">
              陕西、南昌建有两个研发中心和两个生产基地，<br />
              总占地面积约10000㎡，拥有最大的物联网平台。
            </div>
          </div>
        </div>
      </div>
      <div class="ty3">
        <div class="ty3Img">
          <div class="ty3ImgC"></div>
        </div>
        <div class="fj3">
          <div class="year3">
            <div class="fonts3">
              星航小院仔航空俱乐部航模、无人机编程课程体系，<br />
              经过多家小学幼儿园课程试运行，<br />
              得到家长和学生的一致好评，正式上线！
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 故事墙 -->
    <div class="gsqParent">
      <div class="blueTitle2">
        <div class="blueBlock1"></div>
        <div class="blueBlock2">故事墙</div>
      </div>
    </div>
    <div class="gsqContent">
      <div class="gsq2">
        <div class="tiao1"></div>
        <div class="tiao2"></div>
        <div class="zpqParent">
          <div class="row1">
            <img src="../assets/ppjs/1_1.png" class="img1_1" />
            <div class="number1bg">
              <img src="../assets/ppjs/1.png" class="number1" />
            </div>
            <img src="../assets/ppjs/1_2.png" class="img1_2" />
            <div class="number2bg">
              <img src="../assets/ppjs/2.png" class="number2" />
            </div>
          </div>
          <div class="row2">
            <div class="number3bg">
              <img src="../assets/ppjs/3.png" class="number3" />
            </div>
            <img src="../assets/ppjs/2_1.png" class="img2_1" />
            <div class="number4bg">
              <img src="../assets/ppjs/4.png" class="number4" />
            </div>
            <img src="../assets/ppjs/2_2.png" class="img2_2" />
          </div>
          <div class="row3">
            <img src="../assets/ppjs/3_1.png" class="img3_1" />
            <img src="../assets/ppjs/3_2.png" class="img3_2" />
            <img src="../assets/ppjs/3_3.png" class="img3_3" />
          </div>
        </div>
      </div>
    </div>
    <!-- 荣誉墙 -->
    <div class="gsqParent ryq">
      <div class="blueTitle2">
        <div class="blueBlock1"></div>
        <div class="blueBlock2">荣誉墙</div>
      </div>
    </div>
    <div class="ppjsPage_mySwiper_Parent">
      <div class="swiper ppjsPage_mySwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img class="ryImg" src="../assets/ppjs/ry1.png" />
          </div>
          <div class="swiper-slide">
            <img class="ryImg" src="../assets/ppjs/ry2.png" />
          </div>
          <div class="swiper-slide">
            <img class="ryImg" src="../assets/ppjs/ry3.png" />
          </div>
          <div class="swiper-slide">
            <img class="ryImg" src="../assets/ppjs/ry4.png" />
          </div>
          <div class="swiper-slide">
            <img class="ryImg" src="../assets/ppjs/ry5.png" />
          </div>
        </div>
        <!-- <div class="swiper-pagination"></div> -->
      </div>
      <div class="swiper-button-next swiperRight"></div>
      <div class="swiper-button-prev swiperLeft"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'; // 注意引入的是Swiper
import 'swiper/css/swiper.min.css'; // 注意这里的引入
export default {
  name: 'ppjs',
  components: {},
  mounted() {
    new Swiper('.ppjsPage_mySwiper', {
      slidesPerView: 5,
      initialSlide: 2,
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
      //   pagination: {
      //     el: ".swiper-pagination",
      //     clickable: true
      //   }
      navigation: {
        nextEl: '.swiperLeft',
        prevEl: '.swiperRight'
      }
    });
  }
};
</script>
<style lang="less">
.ppjsPage {
  .banner {
    width: 100%;
    display: block;
  }
  .bannerSj {
    width: 0;
    height: 0;
    border-left: 85/19.2vw solid transparent;
    border-right: 85/19.2vw solid transparent;
    border-top: 85/19.2vw solid #0172bb;
    margin: 0 auto;
  }

  .orangeBlueBlock {
    width: 413/19.2vw;
    height: 114/19.2vw;
    position: relative;
    margin: 65/19.2vw auto 94/19.2vw;
    > div {
      width: 400/19.2vw;
      height: 100/19.2vw;
    }
    .orangeBlueBlock1 {
      background: #ff9327;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40/19.2vw;
      font-family: 'Microsoft YaHei';
      color: rgb(255, 255, 255);
      line-height: 40/19.2vw;
      font-style: italic;
      font-weight: bold;
    }
    .orangeBlueBlock2 {
      background: #0e7ab9;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
  }

  .blueTitle1 {
    display: flex;
    align-items: center;
    margin: 60/19.2vw 0;
    .blueBlock1 {
      background: #0a7bd4;
      width: 7/19.2vw;
      height: 66/19.2vw;
      margin-right: 34/19.2vw;
    }
    .blueBlock2 {
      font-size: 40/19.2vw;
      font-family: 'Microsoft YaHei';
      color: rgb(20, 118, 190);
      line-height: 40/19.2vw;
      font-style: italic;
      font-weight: bold;
    }
  }
  .blueTitle2 {
    display: flex;
    align-items: center;
    margin-bottom: 60/19.2vw;
    .blueBlock1 {
      background: #0a7bd4;
      width: 17/19.2vw;
      height: 113/19.2vw;
      margin-right: 34/19.2vw;
    }
    .blueBlock2 {
      font-size: 40/19.2vw;
      font-family: 'Microsoft YaHei';
      color: rgb(20, 118, 190);
      line-height: 40/19.2vw;
      font-style: italic;
      font-weight: bold;
    }
  }

  .ppjsFontsArea {
    width: 1370/19.2vw;
    // height: 723/19.2vw;
    border: 8/19.2vw dotted #037dd6;
    margin: 0 auto;
    padding: 0 34/19.2vw 42/19.2vw;
    .blueTitle {
      margin-bottom: 30/19.2vw;
    }
    .ppjsFonts {
      font-size: 24/19.2vw;
      font-family: 'Microsoft YaHei';
      color: rgb(38, 38, 38);
      line-height: 2;
      padding-left: 30/19.2vw;
      text-indent: 48/19.2vw;
      text-align: left;
      // &.ppjsFonts1 {
      //   text-indent: 48/19.2vw;
      // }
    }
  }

  .fzlcParent {
    position: relative;
    width: 100%;
    height: 1089/19.2vw;
    .malu {
      position: absolute;
      left: 0;
      top: 294/19.2vw;
      width: 1762/19.2vw;
      height: 760/19.2vw;
      background: url('../assets/ppjs/malu.png') no-repeat;
      background-size: 100% 100%;
    }
    .ty1 {
      position: absolute;
      left: 317/19.2vw;
      top: 0;
      width: 243/19.2vw;
      height: 325/19.2vw;
      background: url('../assets/ppjs/ty1.png') no-repeat;
      background-size: 100% 100%;
      .ty1Img {
        position: absolute;
        left: 22/19.2vw;
        top: 20/19.2vw;
        width: 200/19.2vw;
        height: 199/19.2vw;
        background: url('../assets/ppjs/ty1Img.png') no-repeat;
        background-size: 100% 100%;
      }
      .fj1 {
        position: absolute;
        left: 39/19.2vw;
        bottom: -105/19.2vw;
        width: 123/19.2vw;
        height: 104/19.2vw;
        background: url('../assets/ppjs/fj1.png') no-repeat;
        background-size: 100% 100%;
        .year1 {
          position: absolute;
          left: 50/19.2vw;
          top: 90/19.2vw;
          width: 66/19.2vw;
          height: 17/19.2vw;
          background: url('../assets/ppjs/2017.png') no-repeat;
          background-size: 100% 100%;
          .fonts1 {
            white-space: nowrap;
            text-align: center;
            position: absolute;
            left: 50%;
            top: 33/19.2vw;
            transform: translate(-50%, 0);
            font-size: 21/19.2vw;
            font-family: 'Microsoft YaHei';
            color: rgb(38, 38, 38);
            line-height: 1.2;
          }
        }
      }
    }
    .ty2 {
      position: absolute;
      left: 750/19.2vw;
      top: 109/19.2vw;
      width: 380/19.2vw;
      height: 512/19.2vw;
      background: url('../assets/ppjs/ty2.png') no-repeat;
      background-size: 100% 100%;
      z-index: 1;
      .ty2Img {
        position: absolute;
        left: 34/19.2vw;
        top: 32/19.2vw;
        width: 314/19.2vw;
        height: 313/19.2vw;
        background: url('../assets/ppjs/ty2Img.png') no-repeat;
        background-size: 100% 100%;
      }
      .fj2 {
        position: absolute;
        left: 200/19.2vw;
        top: 485/19.2vw;
        width: 149/19.2vw;
        height: 67/19.2vw;
        background: url('../assets/ppjs/fj2.png') no-repeat;
        background-size: 100% 100%;
        .year2 {
          position: absolute;
          left: 106/19.2vw;
          bottom: -31/19.2vw;
          width: 66/19.2vw;
          height: 17/19.2vw;
          background: url('../assets/ppjs/2019.png') no-repeat;
          background-size: 100% 100%;
          .fonts2 {
            white-space: nowrap;
            text-align: center;
            position: absolute;
            left: 50%;
            top: 33/19.2vw;
            transform: translate(-50%, 0);
            font-size: 21/19.2vw;
            font-family: 'Microsoft YaHei';
            color: rgb(38, 38, 38);
            line-height: 1.2;
          }
        }
      }
    }
    .ty3 {
      position: absolute;
      left: 1245/19.2vw;
      top: 305/19.2vw;
      width: 505/19.2vw;
      height: 681/19.2vw;
      background: url('../assets/ppjs/ty3.png') no-repeat;
      background-size: 100% 100%;
      .ty3Img {
        position: absolute;
        left: 45/19.2vw;
        top: 43/19.2vw;
        width: 417/19.2vw;
        height: 416/19.2vw;
        background: url('../assets/ppjs/ty3Img.png') no-repeat;
        background-size: 100% 100%;
        .ty3ImgC {
          position: absolute;
          left: 77/19.2vw;
          top: 14/19.2vw;
          width: 273/19.2vw;
          height: 380/19.2vw;
          background: url('../assets/ppjs/ty3ImgC.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      .fj3 {
        position: absolute;
        left: 77/19.2vw;
        bottom: -94/19.2vw;
        width: 139/19.2vw;
        height: 74/19.2vw;
        background: url('../assets/ppjs/fj3.png') no-repeat;
        background-size: 100% 100%;
        .year3 {
          position: absolute;
          left: 33/19.2vw;
          bottom: -26/19.2vw;
          width: 66/19.2vw;
          height: 17/19.2vw;
          background: url('../assets/ppjs/2021.png') no-repeat;
          background-size: 100% 100%;
          .fonts3 {
            white-space: nowrap;
            text-align: center;
            position: absolute;
            left: 50%;
            top: 33/19.2vw;
            transform: translate(-50%, 0);
            font-size: 21/19.2vw;
            font-family: 'Microsoft YaHei';
            color: rgb(38, 38, 38);
            line-height: 1.2;
          }
        }
      }
    }
  }

  .gsqParent {
    width: 1300/19.2vw;
    margin: 0 auto;
    &.ryq {
      margin-top: 95/19.2vw;
    }
  }
  .gsqContent {
    width: 100%;
    height: 926/19.2vw;
    background: url('../assets/ppjs/gsq1.png') no-repeat;
    background-size: 100% 100%;
    padding: 33/19.2vw 0 28/19.2vw;
    .gsq2 {
      width: 100%;
      height: 100%;
      background: url('../assets/ppjs/gsq2.png') no-repeat;
      background-size: 100% 100%;
      padding-top: 132/19.2vw;
      position: relative;
      .zpqParent {
        position: absolute;
        left: 50%;
        top: 153/19.2vw;
        transform: translate(-50%, 0);
        width: 1270/19.2vw;
        .row1 {
          display: flex;
          .img1_1 {
            width: 201/19.2vw;
            height: 192/19.2vw;
            margin-right: 18/19.2vw;
          }
          .number1bg {
            width: 198/19.2vw;
            height: 180/19.2vw;
            margin-top: 15/19.2vw;
            margin-right: 19/19.2vw;
            position: relative;
            background: url('../assets/ppjs/1bg.png') no-repeat;
            background-size: 100% 100%;
            .number1 {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 41/19.2vw;
              height: 113/19.2vw;
            }
          }
          .img1_2 {
            width: 400/19.2vw;
            height: 179/19.2vw;
            margin-top: 22/19.2vw;
            margin-right: 18/19.2vw;
          }
          .number2bg {
            width: 416/19.2vw;
            height: 193/19.2vw;
            margin-top: 1/19.2vw;
            position: relative;
            background: url('../assets/ppjs/2bg.png') no-repeat;
            background-size: 100% 100%;
            .number2 {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 70/19.2vw;
              height: 116/19.2vw;
            }
          }
        }
        .row2 {
          display: flex;
          margin-top: 2/19.2vw;
          margin-bottom: 7/19.2vw;
          .number3bg {
            width: 199/19.2vw;
            height: 188/19.2vw;
            margin-right: 18/19.2vw;
            position: relative;
            background: url('../assets/ppjs/3bg.png') no-repeat;
            background-size: 100% 100%;
            .number3 {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 68/19.2vw;
              height: 118/19.2vw;
            }
          }
          .img2_1 {
            width: 201/19.2vw;
            height: 183/19.2vw;
            margin-top: 3/19.2vw;
            margin-right: 18/19.2vw;
          }
          .number4bg {
            width: 399/19.2vw;
            height: 177/19.2vw;
            margin-top: 5/19.2vw;
            margin-right: 19/19.2vw;
            position: relative;
            background: url('../assets/ppjs/4bg.png') no-repeat;
            background-size: 100% 100%;
            .number4 {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 79/19.2vw;
              height: 113/19.2vw;
            }
          }
          .img2_2 {
            width: 415/19.2vw;
            height: 188/19.2vw;
          }
        }
        .row3 {
          display: flex;
          .img3_1 {
            width: 419/19.2vw;
            height: 197/19.2vw;
            margin-top: 1/19.2vw;
            margin-right: 18/19.2vw;
          }
          .img3_2 {
            width: 403/19.2vw;
            height: 173/19.2vw;
            margin-top: 3/19.2vw;
            margin-right: 11/19.2vw;
          }
          .img3_3 {
            width: 419/19.2vw;
            height: 194/19.2vw;
          }
        }
      }
    }
    .tiao1 {
      width: 1545/19.2vw;
      height: 58/19.2vw;
      background: url('../assets/ppjs/tiao1.png') no-repeat;
      background-size: 100% 100%;
      margin: 0 auto 522/19.2vw;
    }
    .tiao2 {
      width: 1545/19.2vw;
      height: 58/19.2vw;
      background: url('../assets/ppjs/tiao2.png') no-repeat;
      background-size: 100% 100%;
      margin: 0 auto;
    }
  }

  .ppjsPage_mySwiper_Parent {
    width: 1738/19.2vw;
    height: 487/19.2vw;
    position: relative;
    padding: 0 60/19.2vw;
    margin: 0 auto 189/19.2vw;
    .swiperLeft {
      position: absolute;
      width: 40/19.2vw;
      height: 68/19.2vw;
      background: url('../assets/ppjs/swiperLeft.png');
      background-size: 100% 100%;
      top: 50%;
      transform: translate(0, -50%);
      left: 0;
      cursor: pointer;
    }
    .swiperRight {
      position: absolute;
      width: 40/19.2vw;
      height: 68/19.2vw;
      background: url('../assets/ppjs/swiperRight.png');
      background-size: 100% 100%;
      top: 50%;
      transform: translate(0, -50%);
      right: 0;
      cursor: pointer;
    }
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    display: none;
  }
  .ppjsPage_mySwiper {
    width: 1532/19.2vw;
    height: 487/19.2vw;
    overflow: hidden;
  }

  .swiper-slide {
    position: relative;
    top: 76/19.2vw;
    width: 274/19.2vw;
    height: 410/19.2vw;
    .ryImg {
      width: 274/19.2vw;
      height: 410/19.2vw;
    }
  }
  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    position: relative;
    top: 0;
  }
}
</style>
